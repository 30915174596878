import { Modal, ModalFooter, Form } from "react-bootstrap";
import { useState } from "react";
import PropTypes from "prop-types";

const DigitainEditProviderModal = (props) => {

  //props 
  const {
    onSaveProviderDetailsClickedCallback,
    onCancelClickedCallback,
    providerToEdit,
    ...modalProps
  } = props;

  //State
  const [providerDisplayName, setProviderDisplayName] = useState(() =>
    providerToEdit ? providerToEdit.providerDisplayName : ""
  );
  const [image, setImage] = useState(""); // State to store the image
  const [imageFile, setImageFile] = useState(null); // State to store the file object


  //Methods
  // Image preview and file selection handler
  const handleImagePreview = (e) => {
    const imageAsFile = e.target.files[0];
    setImage(URL.createObjectURL(imageAsFile)); // Preview the image
    setImageFile(imageAsFile); // Store the file object
  };
  
  // onSaveProvider btn click handler
  const onSaveProviderDetailsClicked = () => {
    let providerDetails = {
      providerId: providerToEdit.providerId,
      providerDisplayName,
      image: imageFile, // Save the file object,
      providerName:providerToEdit.providerName
    };
    onSaveProviderDetailsClickedCallback(providerDetails);
  };
  
  //onCancel btn click handler
  const onCancelClicked = () => {
    onCancelClickedCallback();
  };

  return (
    <Modal
      centered
      // backdrop="static"
      // keyboard={false}
      {...modalProps}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Provider</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        
        <Form.Group className="form-group mb-3">
          <Form.Label>Provider Display Name</Form.Label>
          <Form.Control
            type="text"
            value={providerDisplayName}
            onChange={(e) => setProviderDisplayName(e.target.value)}
            required
          />
        </Form.Group>

        {/* Image Upload Section */}
        <Form.Group className="form-group mb-3">
          <Form.Label>Provider Image</Form.Label>
          <Form.Control
            type="file"
            accept=".jpg,.png,.jpeg,.gif"
            onChange={handleImagePreview}
          />
          <small>Recommended size: 1200 X 480 pixels.</small>
          {image && (
            <>
              <img className="preview mt-2" src={image} alt="Preview" style={{ width: 'auto' ,backgroundColor: '#413a69'}} />
            </>
          )}
        </Form.Group>

      </Modal.Body>
      <ModalFooter>
        <div className="actions">
          <button className="btn btn-danger" onClick={onCancelClicked}>
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={onSaveProviderDetailsClicked}
          >
            Save
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

DigitainEditProviderModal.propTypes = {
  providerToEdit: PropTypes.object,
  onSaveProviderDetailsClickedCallback: PropTypes.func,
  onCancelClickedCallback: PropTypes.func,
};

export { DigitainEditProviderModal };
