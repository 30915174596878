/* eslint-disable react/prop-types */
import { Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Get, Postdata } from '../../../utils/api.service';
import DataTableBase from "../../common/DataTableBase";
import HttpService from "../../../utils/http.service";
// import { ConvertToDMY, ConvertToDMYHIS, CheckPermission } from "../../../utils/Helper";
import { useDispatch, useSelector } from "react-redux";
import { message } from "../../../redux/actions/toastActions";
import ModalPopup from "../../common/Modal";
import '../../../styles/Profile.scss'
// import CSV from "../../common/CSV";
import Link from "../../common/Link";
import { DigitainEditProviderModal } from "./DigitainEditProviderModal";
import { isPermission } from "../../../redux/selectors/authSelector";
import { CheckPermission } from "../../../utils/Helper";

const DigitainProviderList = () => {
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setperPage] = useState(process.env.REACT_APP_RecordPerPage);
  const [currentPage, setcurrentPage] = useState(1);
  const [refresh, setRefresh] = useState(true);
  const dispatch = useDispatch();
  const [providerToEdit, setProviderToEdit] = useState(null);

  const [modalswitch, setmodalswitch] = useState(false);
  const [modalswitchcontent, setmodalswitchcontent] = useState({});
  const permission = useSelector(isPermission);

  const columns = [
    {
      name: "#",
      width: "55px",
      selector: (row, i) => currentPage * perPage + (i + 1) - perPage,
    },
    {
      name: "Provider Id",
      selector: (row) => row.providerId,
    },
    {
      name: "Provider Name",
      selector: (row) => row.providerDisplayName,
    },
    {
      name: "Status",
      cell: (row) => (
        <Link permission={"/digitain/game-sort/s"} status={row.isEnabled.toString()}>
          <Form.Check
            type="switch"
            checked={row.isEnabled}
            onChange={(e) =>
              handleStatusChange(row.providerId, e.target.checked)
            }
          />
        </Link>
      ),
      button: true,
    },
    {
      name: "Action",
      id: "actions",
      hide: !CheckPermission(permission, "/digitain/game-sort/s") && 999999,
      cell: (row) => (
        <span className="actions">
          <i
            className="mdi mdi-pen"
            onClick={() => onEditProviderNameClicked(row.providerId)}
          />
        </span>
      ),
      button: true,
    },
  ];

  const handleStatusChange = (id, status) => {
    setmodalswitchcontent({id, status});
    setmodalswitch(true);
  };


  const handleStatusChangeAction = () => {
    setmodalswitch(false);
    setIsloading(true);
    Postdata(HttpService.Digitain_provider, {
      providerId: modalswitchcontent.id,
      status: modalswitchcontent.status
    }).then((res) => {
      //TODO: Stop refreshing the whole page and update only the gamedata which was updated or look for other option other than refresh.
      setRefresh(!refresh);
      dispatch(message(res.message));
    }).catch((err) => {
      console.error(err.toString());
      dispatch(message(err.message));
    });
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setperPage(newPerPage);
    setcurrentPage(page);
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  //Edit provider name modal callbacks
  const onEditProviderNameClicked  = (providerId) => {
    let provideDataToEdit = data.find(providerData => providerData.providerId === providerId);
    console.log('provideDataToEdit :>> ', provideDataToEdit);
    setProviderToEdit(provideDataToEdit);
  }

  const onCancelClickedCallback = () => {
    setProviderToEdit(null);
  }

  const onSaveProviderDetailsClickedCallback = ({providerId, providerDisplayName,image,providerName}) =>  {
    if(providerDisplayName !== null && providerDisplayName !== ""){

      const formData = new FormData();
      formData.append('providerId', providerId);
      formData.append('providerDisplayName', providerDisplayName);


      // Append the image file (make sure `imageFile` is coming from the modal)
      if (image) {
        const extension = image.name.split('.').pop(); // Get file extension
        const imageFileName = `${providerName}.${extension}`; // Rename the image as per backend requirement
        formData.append('image', image, imageFileName);
      }


      //call back end api
      Postdata(HttpService.Digitain_provider, formData ,{
        headers: {
          'Content-Type': 'multipart/form-data' // Required for file uploads
        }
      })
        .then((res) => {
          //TODO: Stop refreshing the whole page and update only the gamedata which was updated or look for other option other than refresh.
          setRefresh(!refresh);
          dispatch(message(res.message));
        })
        .catch((err) => {
          console.error(err.toString());
          dispatch(message(err.message));
        })
        .finally(() => {
          setProviderToEdit(null);
        });
    }else{
      dispatch(message("Provider Name cannot be empty"));
      setProviderToEdit(null);
    }
  }


  useEffect(() => {
    setIsloading(true);
    let params = {
      page: currentPage,
      perpage: perPage,
      // search: (search ? search : ''),
    }
    Get(HttpService.Digitain_provider,{params}).then((response) => {
      // console.log(response)
      if (response.status === 200) {
        setData(response.data);
        // console.log('response.data :>> ', response.data);
        setTotalRows(response.count);
        setIsloading(false);
      } else {
        setIsloading(false);
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
    });
  }, [currentPage, perPage, refresh]);

  return (
    <>
      <ModalPopup
        className="alert-box-popup"
        show={modalswitch}
        size="sm"
        onHide={() => setmodalswitch(false)}
        heading="Are you sure?"
      >
        <i className="mdi mdi-check"></i>
        <p>You want to change this status!</p>
        <div className="actions">
          <button
            className="btn btn-primary"
            onClick={() => handleStatusChangeAction()}
          >
            Yes, Change Status
          </button>
          <button
            className="btn btn-danger"
            onClick={() => setmodalswitch(false)}
          >
            Cancel
          </button>
        </div>
      </ModalPopup>
      {providerToEdit && <DigitainEditProviderModal
        show={providerToEdit !== null}
        providerToEdit={providerToEdit}
        onSaveProviderDetailsClickedCallback={
          onSaveProviderDetailsClickedCallback
        }
        onCancelClickedCallback={onCancelClickedCallback}
        onHide={onCancelClickedCallback}
      />}

      <div className="card-body filters">
        <div className="row searchaction">
          <div className="col-lg-6">
            <h5>Provider Management</h5>
          </div>
        </div>

        {/* <Collapse in={open}>
          <div>
            <div className="card">
              <p className="card-header">Search</p>
              <div className="card-body">
                <Form.Group className="form-group mb-0">
                  <Form.Label>Search</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={e => setSearch(e.target.value)}
                    required
                  />
                </Form.Group>
              </div>
            </div>
          </div>
        </Collapse> */}

        <DataTableBase
          columns={columns}
          loader={isloading}
          data={data}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </div>
    </>
  );
}

export { DigitainProviderList }